import { useFeatureFlags } from '@/Hooks/Company/useFeatureFlags'

export default function useZillowMediaExclusivesNavigation() {
  const { hasFeatureFlag } = useFeatureFlags()

  return {
    overviewTitle: 'Zillow media exclusives',
    overviewRouteName: 'web.company.zillow-media-exclusives.index',
    sections: [
      {
        show: true,
        name: 'Media Exclusives',
        description: '',
        children: [
          {
            show: hasFeatureFlag('zillow_photographer_branding'),
            comingSoon: false,
            name: 'Photographer Branding and HD Photos',
            description:
              "Boost your brand. High resolution photos and your company's name and logo will be displayed in Zillow's photo viewer.",
            routeIsInertia: true,
            targetRouteName:
              'web.company.zillow-media-exclusives.zillow-photographer-branding.show',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/files/zillow-media-exclusives/photographer-branding-hd-photos.png',
            imageObjectFit: 'contain',
            routeNamesToCheckIfCurrent: [
              'web.company.zillow-media-exclusives.zillow-photographer-branding.*',
            ],
            integrationProvider: 'ZILLOW_PHOTOGRAPHER_BRANDING',
            shouldShowBadge: true,
          },
          {
            show: hasFeatureFlag('zillow_rentals'),
            comingSoon: false,
            name: 'Zillow Rentals',
            description:
              'Get recommended to Zillow Rental Partners by joining a curated list of media ' +
              'providers who can capture Zillow 3D Home tours and interactive floor plans.',
            routeIsInertia: true,
            targetRouteName: 'web.company.zillow-media-exclusives.zillow-rentals.show',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/files/zillow-media-exclusives/zillow-rentals.png',
            imageObjectFit: 'contain',
            routeNamesToCheckIfCurrent: ['web.company.zillow-media-exclusives.zillow-rentals.*'],
            integrationProvider: 'ZILLOW_RENTALS',
            shouldShowBadge: true,
          },
          {
            // TODO - Modify show prop to use hasFeatureFlag when ready to launch feature
            show: true,
            comingSoon: true,
            name: 'Zillow 3D Home',
            description:
              'Streamline your workflow and sync tours directly to your listings with the 3D Home Integration.',
            routeIsInertia: true,
            targetRouteName: 'web.company.zillow-media-exclusives.zillow-3d-home.show',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/files/zillow-media-exclusives/zillow-3d-home.png',
            routeNamesToCheckIfCurrent: ['web.company.zillow-media-exclusives.zillow-3d-home.*'],
            integrationProvider: 'ZILLOW_3D_HOME',
            shouldShowBadge: true,
          },
          {
            show: true,
            comingSoon: true,
            name: 'More Exclusives',
            description:
              'Your experience is about to get even better! New features and enhancements are coming soon!',
            image:
              'https://aryeo.sfo2.cdn.digitaloceanspaces.com/aryeo_media/files/zillow-media-exclusives/more-media-exclusives.png',
            imageObjectFit: 'contain',
            shouldShowBadge: true,
          },
        ],
      },

      {
        show: true,
        name: 'Settings',
        description: '',
        children: [
          {
            show: true,
            name: 'Zillow Profile',
            description:
              'Create and manage your company profile that will be used across the Zillow media exclusives you have enabled for your company.',
            routeIsInertia: true,
            targetRouteName: 'web.company.zillow-media-exclusives.profile.edit',
            routeNamesToCheckIfCurrent: ['web.company.zillow-media-exclusives.profile.*'],
            shouldShowBadge: false,
          },
        ],
      },
    ],
  }
}
